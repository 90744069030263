import { template as template_579a8671c95a4fe18be1b8728b613975 } from "@ember/template-compiler";
const FKText = template_579a8671c95a4fe18be1b8728b613975(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
