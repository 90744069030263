import { template as template_2798a2150223440fb1608e6b17a3ada5 } from "@ember/template-compiler";
const FKLabel = template_2798a2150223440fb1608e6b17a3ada5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
